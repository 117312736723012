<template>
  <div ref="section" class="columns-content -visibility-auto">
    <modal v-if="currentVideo" :isOpen="currentVideo" @close="closeModal" full-width="true">
      <video-player ref="player" :item="currentVideo" in-modal="true" play-fullscreen="false"></video-player>
    </modal>
    <section>
      <div class="container flex-wrap" :class="{'-full-video': countVideo === 3}">
        <div v-for="(item, i) in slideItems" :key="i" :index="i" class="slide"
        :class="{'order-1 order-md-0': item.type === 'text', '-video': item.type === 'video', 'slide-full-width': slideItems.length === 2 && i == 1}">
          <Observer name="fade-up" v-if="item.type === 'text'" :class="'delay-' + i" @enter.once="preCallGTM(item, i)">
            <div class="bloc -text">
              <div class="content">
                <span class="headline" v-if="item.headline" v-text="item.headline"></span>
                <h2 class="h4 title -upper" v-if="item.title" v-text="item.title"></h2>
                <h3 class="h6 subtitle -upper" v-if="item.description" v-text="item.description"></h3>
                <div class="h6 wysiwyg" v-if="item.text" v-html="item.text"></div>
                <router-link v-if="item.ctaText && item.ctaLink" :to="localizedUrl + item.ctaLink" class="btn -stroke" @click.native="callGTM('eePromoClick', item.title, i, localizedUrl + item.ctaLink)">{{ item.ctaText }}</router-link>
                <button type="button" v-else-if="item.ctaText && item.ctaVideo" class="btn -stroke" @click="playVideo(item)">{{ item.ctaText }}</button>
              </div>
            </div>
          </Observer>
          <Observer name="fade-up" v-else :class="'delay-' + i" @enter.once="preCallGTM(item, i)">
            <div class="bloc -visual" :class="item.type">
              <router-link v-if="item.type === 'image' && item.ctaLink" :to="localizedUrl + item.ctaLink" class="bloc-picture" :class="{'-layer -darker-layer' : item.ctaText }" @click.native="callGTM('eePromoClick', item.ctaText, i, localizedUrl + item.ctaLink)">
                <lazy-image class-name="bloc-picture__image" ref="picture" :item="item.picture" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
                <div v-if="item.ctaText" class="info">
                  <p class="info-label h5"> {{ item.ctaText }} </p>
                </div>
              </router-link>
              <div class="img-container" v-else-if="item.type === 'image' && !item.ctaLink">
                <lazy-image class-name="bloc-picture__image" ref="picture" :item="item.picture" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
              </div>
              <a href="#" role="button" v-else-if="item.type === 'video'" @click.prevent="playVideo(item)" class="bloc-picture -layer -darker-layer">
                <lazy-image class-name="bloc-picture__image" ref="picture" :item="item.picture" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
                <div class="info">
                  <p v-if="item.ctaText" class="info-label h5"> {{ item.ctaText }} </p>
                  <p v-else class="info-label h5">{{ $t('Watch the video') }}</p>
                </div>
                <i class="icon-play"></i>
              </a>
            </div>
          </Observer>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'
import LazyImage from '../components/utils/LazyImage'
import { getRouteName } from '../utilities'

export default {
  props: ['items', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  name: 'three_columns',
  components: {
    VideoPlayer: () => import(/* webpackChunkName: "video-player" */ './VideoPlayer'),
    Modal: () => import(/* webpackChunkName: "modal-cpt" */ '../components/utils/Modal'),
    LazyImage
  },
  data() {
    return {
      countVideo: 0,
      currentSlide: 0,
      currentVideo: null,
      hooperSettings: {
        initialSlide: 0,
        itemsToShow: 1,
        itemsToSlide: 1,
        transition: 400,
        autoPlay: false,
        mouseDrag: true,
        shortDrag: true,
        touchDrag: true,
        hoverPause: false,
        keysControl: true,
        wheelControl: false,
        trimWhiteSpace: true,
        infiniteScroll: false,
        breakpoints: {
          768: {
            itemsToShow: 3,
            itemsToSlide: 3,
            mouseDrag: false,
            shortDrag: false,
            touchDrag: false,
            keysControl: false,
            trimWhiteSpace: false
          }
        }
      }
    }
  },
  mounted () {
    this.countVideo = this.items.filter(i => i.type === 'video').length
  },
  computed: {
    localizedUrl () {
      return currentStoreView().url || ''
    },
    slideItems() {
      return this.items.map(item => {
        let pictureUrl = (item.type === 'image') ? item.url : item.coverUrl
        let videoUrl = (item.type === 'video') ? item.url : item.ctaVideo
        let obj = {
          type: item.type,
          title: item.title,
          headline: item.headline,
          description: item.description,
          text: item.text,
          ctaText: item.ctaText,
          ctaLink: item.ctaLink,
          ctaVideo: item.ctaVideo,
          video: {
            url: videoUrl,
            subtitle: item.subtitle
          },
          picture: {
            url: pictureUrl,
            lowDefinitionUrl: ImageManager.getLowDefinition(pictureUrl, 200, 1.222),
            srcset: ImageManager.getSrcSet(pictureUrl, 1400, 300, 100, 1.222),
            alt: item.alt,
            width: item.width,
            height: item.height
          }
        }
        return obj
      })
    },
    pictureSizes() {
      return '(min-width: 768px) 31vw, (min-width: 1200px) 400px, 80vw'
    }
  },
  methods: {
    closeModal() {
      this.modalOpen = false
      this.currentVideo = null
    },
    slideTo (i) {
      this.$refs.carousel.slideTo(i)
    },
    updateProgress (payload) {
      this.currentSlide = payload.currentSlide
    },
    playVideo(item) {
      this.currentVideo = (!this.currentVideo) ? item.video : null
      if (this.currentVideo) {
        setTimeout(() => {
          this.$refs.player.play()
        }, 600)
      }
    },
    progressAriaLabel (i) {
      return this.currentSlide === i ? this.$t('Slide') + ' ' + i + ' ' + this.$t('(current slide)') : this.$t('Slide') + ' ' + i
    },
    preCallGTM(item, pos) {
      this.$refs.section.classList.remove('-visibility-auto')
      if (item.type === 'text') {
        if (item.ctaText && item.ctaLink) {
          this.callGTM('eePromoView', item.title, pos)
        }
      } else {
        if (item.type === 'image' && item.ctaLink) {
          this.callGTM('eePromoView', item.ctaText, pos)
        }
      }
    },
    callGTM(event, title, pos, link) {
      if (getRouteName(this.$route.name) === 'home') {
        let data = {
          event: event,
          promotion: {
            creative: 'mozaik',
            id: 'mozaik_' + this.pagePosition,
            name: title,
            position: pos
          }
        }
        if (link) {
          data.nextPageVisited = link
        }
        this.$gtm.trackEvent(data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.player-overlay {
  position:absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
}

.columns-content {
  margin-bottom: 4rem;

  & +.columns-content {
    margin-top: -4rem;
  }

  .container {
    max-width: 120rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-left: 3rem;
    padding-right: 3rem;

    @include bp(max md) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      grid-template-columns: repeat(2, 1fr);

      &.-full-video {
        .slide.-video {
          &.-video {
            width: 100%;
            max-width: 100%;
            flex-basis: 100%;

            .bloc {
              min-height: 100vw;
            }
          }
        }
      }
    }

  }
}

.slide {
  text-align: center;
  padding: 1rem 1rem;
  height: auto;
  outline: none;
  max-width: 100%;

  &.slide-full-width {
    @include bp(max md) {
      grid-column: 1 / 3;
    }
  }

  @include bp(max md) {
    padding: 1rem 1rem;

    &.order-1 {
      grid-column: 1 / 3;
      width: 100%;
      max-width: 100%;
    }

    &:not(.order-1) {
      order: 2;
    }
  }

  /deep/ .obs {
    height: 100%;
  }
}
.bloc {
  display: flex;
  height: 100%;
  min-height: 44rem;
  position: relative;
  padding: 5rem 2rem 5rem;
  flex-direction: column;

  @include bp(max md) {
    min-height: 70vw;
  }

  &.-text {
    flex-direction: row;
    align-items: center;

    @include bp(max md) {
      min-height: auto;
      padding: 4rem 0 2rem 0;
    }
  }
  .content {
    flex: 1;
  }

  &-picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    &.-darker-layer {
      &:before {
        background: rgba(0, 0, 0, 0);
      }
    }
    .info {
      color: var(--zenith-component-slider-limited-edition-image-text);
      margin-bottom: 1rem;
      z-index: 20;
      opacity: 0;
      width: 100%;
      padding: 0 4rem;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &-label {
        opacity: 0;
        text-transform: uppercase;
        font-weight: 500;
        transform: translateY(3rem);
        @include appearTitle(0ms);
      }
    }
    &:hover {
      .info{
        opacity: 1;
        &-label {
          opacity: 1;
          transform: translateY(0);
          @include appearTitle(100ms, 0ms, 800ms, 200ms);
        }
      }
      .icon {
        &-play {
          opacity: 0;
        }
      }
      &.-darker-layer {
        &:before {
          background: rgba(0, 0, 0, 0.6);
        }
      }
    }

    &__image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 100%;
      height: 100%;
      transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);

      /deep/ img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .icon {
      border-radius: 100%;
      &-play {
        content:"";
        z-index: 10;
        background: rgba(0,0,0,.6);
        position: absolute;
        bottom: 2rem;
        right: 2rem;
        margin-bottom: 0;
        padding: 2rem;
        border-radius: 100%;
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 11px;
          left: 15px;
          border-style: solid;
          border-width: 8px 0 8px 13px;
          border-color: transparent transparent transparent rgba(255, 255, 255, .8);
        }
      }
    }
  }

  i {
    font-size: em(30);
    line-height: normal;
    margin-bottom: 2rem;
  }

  .headline {
    display: block;
    margin-bottom: 1rem;
    font-size: em(16);
    font-weight: 500;
    letter-spacing: em(2, 16);
    text-transform: uppercase;
    color: var(--zenith-black);
  }
  .title {
    margin-bottom: 2rem;
    font-weight: 500;
    color: var(--zenith-black);
  }
  .subtitle {
    margin-top: -3rem;
    margin-bottom: 3rem;
    color: var(--zenith-component-slider-limited-edition-subtitle);
  }
  .wysiwyg {
    margin-bottom: 3rem;
    font-size: em(16);
    letter-spacing: em(.2, 16);
    // color: var(--zenith-component-slider-limited-edition-text);
    color: var(--zenith-component-title-text-center-text);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.-visual {
    border-radius: .5rem;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &.-video {
    /deep/ .plyr {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

</style>
